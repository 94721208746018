import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Prototyping The Third Door`}</h1>
    <p>{`Yo!`}</p>
    <p>{`First off, thanks for all of the feedback over the past two days to the slack
format!`}</p>
    <p>{`We are making some tweaks today - more context to set the conversation up,
making it easier to read on both mobile and desktop, giving you some audio so
you can see our OBT for the day, and a video...more multimedia!`}</p>
    <p>{`Now, let's get to it`}</p>
    <hr></hr>
    <p>{`The Facebook ad account is still blocked today, not a huge shock since we ran
the distorted Zuck face ad lol..`}</p>
    <p>{`So today, we could create a new ad account, but instead we’re gonna focus on
venture two.`}</p>
    <p><strong parentName="p">{`Venture two is all about building a course (the digital product) from
scratch.`}</strong></p>
    <p>{`This is really appealing because it doesn’t require any code, has high margins,
and ties into the thing I love the most, teaching.`}</p>
    <p><strong parentName="p">{`The course is centered around the idea of helping entrepreneurs understand and
bust through the third door.`}</strong></p>
    <p><strong parentName="p">{`I always thought there were only two doors.`}</strong></p>
    <p>{`Door 1: Getting a job`}</p>
    <p>{`Door 2: Starting something from the ground up and scaling it`}</p>
    <p>{`And then I started seeing people tweet about how they put like `}{`$`}{`50,000 down and
owned a million dollar business. Seemed too good to be true, so I started
looking into it and I’ve become confident there’s a third door.`}</p>
    <p>{`Door 3: Buying and growing a business`}</p>
    <p><strong parentName="p">{`Now that we’ve figured out what the course is about (maybe on another day,
i'll show you all the topics that were in the initial list of potential
courses) - it’s time to prototype it
(`}<a parentName="strong" {...{
          "href": "https://soundcloud.com/shaan-puri-429311607/all-access-pass-930-daily-audio/s-pMhO38Rpw2G"
        }}>{`click here to get the audio where we decide to prototype it`}</a>{`).`}</strong></p>
    <p>{`Prototyping means a million different things to everyone. And I’m pretty sure
i’ve tried every approach in the books before.`}</p>
    <p><strong parentName="p">{`Here’s just a few possible ways that we could start prototpying:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Approach 1:`}</strong>{` Start building the curriculum - this is tempting and could be
fun, since i love teaching, but need to figure out what people are buying
first`}</li>
      <li parentName="ul"><strong parentName="li">{`Approach 2:`}</strong>{` Do a bunch of research - limited value and typically an excuse
to doing actual work`}</li>
      <li parentName="ul"><strong parentName="li">{`Approach 3:`}</strong>{` Spin up some test landing page and ads - we’re not quite ready
for customers yet, need to figure out our big mario first!`}</li>
    </ul>
    <p><strong parentName="p">{`The old me would probably jump to one of these, but now i’m all about creating
an "internal prototype" - basically focusing only on the big mario/value prop to
the end user in the lowest fidelity possible way.`}</strong></p>
    <p>{`The goal here is to figure out what story and positioning would fire Ben and I
up about it, if we saw it on Twitter.`}</p>
    <p>{`The first step is to be a great artist (thanks again, Picasso!) by looking at
who’s doing it right and reverse engineering how they talk about their course.`}</p>
    <p>{`And then built the internal prototype in Figma (`}<strong parentName="p">{`click
`}<a parentName="strong" {...{
          "href": "https://www.loom.com/share/7f0f936507d14298854ae34ecfdde45e"
        }}>{`here`}</a></strong>{`)`}</p>
    <div style={{
      "position": "relative",
      "paddingBottom": "56.25%",
      "height": "0"
    }}>
  <iframe src='https://www.loom.com/embed/7f0f936507d14298854ae34ecfdde45e' frameBorder='0' webkitallowfullscreen mozallowfullscreen allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe>
    </div>
    <br />
    <p><strong parentName="p">{`Now that we have a bunch of ideas around our prototype in Figma, tomorrow is
about figuring out the lead magnets and key pieces of content to build the
waitlist and audience with..`}</strong></p>
    <p>{`Tbh, I didn’t get as much done as i wanted to today, I had a lot of work and i’m
officially selling my house, so i have to run to san francisco now!`}</p>
    <p>{`Until tomorrow,`}</p>
    <p>{`shaan`}</p>
    <Links links={[{
      title: LinkLabel.Multimedia,
      links: [{
        url: 'https://soundcloud.com/shaan-puri-429311607/all-access-pass-930-daily-audio/s-pMhO38Rpw2G',
        label: '9.30.20 Daily Standup'
      }, {
        url: 'https://www.loom.com/share/7f0f936507d14298854ae34ecfdde45e',
        label: 'Figma Landing Page Prototyping Loom'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.spoiler_alert_targeting_tai_lopez_s_audience_is_working.label,
      slug: slugify(postMap.spoiler_alert_targeting_tai_lopez_s_audience_is_working.slug)
    }} nextButton={{
      text: postMap.the_internet_doesnt_care_about_geography.label,
      slug: slugify(postMap.the_internet_doesnt_care_about_geography.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      